import { useRouter } from "next/router";
import React, { ReactElement } from "react";
import { Container } from "react-bootstrap";
import Login from "../../components/Forms/Login";
import { ContentLayoutUltraNarrow } from "../../components/_Layout/ContentLayout";
import PageLayout from "../../components/_Layout/PageLayout";
import { Routes } from "../../libs/constants";

export default function index(): ReactElement {
  const router = useRouter();

  const onLogin = () => {
    router.replace(Routes.Home);
  };
  return (
    <PageLayout>
      <main className="mt-5">
        <Container>
          <ContentLayoutUltraNarrow className="mx-auto">
            <Login onSubmit={onLogin} withRegistration={false} />
          </ContentLayoutUltraNarrow>
        </Container>
      </main>
    </PageLayout>
  );
}
